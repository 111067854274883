/* eslint-disable */
import { Module } from 'vuex';
import { RootState } from '..';
import { getAllChorusConfigAsync } from '../../api/chorus/chorusConfigApi';
import { getAllInvoicesAsync } from '../../api/chorus/chorusInvoiceApi';
import { getInvoiceSubmissionStatusAsyncByIds } from '../../api/chorus/chorusInvoiceSubmissionApi';
import ChorusInvoice from '../../models/Chorus/ChorusInvoice';
import ChorusInvoiceSubmissionResponse from '../../models/Chorus/ChorusInvoiceSubmissionResponse';

export interface Filters {
  itemPerPage: number;
  search?: string;
  order?: {
    property: string;
    type: 'ASC' | 'DESC';
  };
  date?: {
    before?: string;
    after?: string;
  };
  validity?: boolean;
}

export interface Configuration {
  id: string;
  account: string;
  supplierId: string;
}

export interface ChorusState {
  page: number;
  items: Array<ChorusInvoice>;
  selected: Array<ChorusInvoice>;
  totalItems?: number;
  filters: Filters;
  configuration?: Configuration;
}

export const types = {
  // Dispatch
  LOAD_INVOICES: 'CHORUS_LOAD_INVOICES',
  UPDATE_FILTERS: 'CHORUS_UPDATE_FILTERS',
  LOAD_STATUS: 'CHORUS_LOAD_STATUS',
  LOAD_MORE: 'CHORUS_LOAD_MORE',
  LOAD_CONFIGURATION: 'CHORUS_LOAD_CONFIGURATION',

  // Commit
  SET_INVOICES: 'CHORUS_SET_INVOICES',
  SET_MORE_INVOICES: 'CHORUS_SET_MORE_INVOICES',
  SET_TOTAL_ITEMS: 'CHORUS_SET_TOTAL_ITEMS',
  SET_FILTERS: 'CHORUS_SET_FILTERS',
  SET_STATUS: 'CHORUS_SET_STATUS',
  SET_PAGE: 'CHORUS_SET_PAGE',
  SET_SELECTED: 'CHORUS_SET_SELECTED',
  TOGGLE_INVOICE: 'CHORUS_TOGGLE_INVOICE',
  SET_CONFIGURATION: 'CHORUS_SET_CONFIGURATION',
};

const getAllInvoices = async (state: ChorusState) => {
  const orderDate = state.filters.order?.property === 'date'
    ? state.filters.order.type
    : undefined;

  const orderReference = state.filters.order?.property === 'reference'
    ? state.filters.order.type
    : undefined;

  return getAllInvoicesAsync(
    state.page,
    state.filters.itemPerPage,
    state.filters.search,
    orderDate,
    orderReference,
    state.filters.validity,
    state.filters.date?.before,
    state.filters.date?.after,
  );
};

export default {
  state: (): ChorusState => ({
    items: [],
    selected: [],
    filters: {
      itemPerPage: 40,
      order: {
        property: 'date',
        type: 'DESC',
      },
    },
    page: 1,
  }),
  mutations: {
    [types.SET_INVOICES]: (state, payload: Array<ChorusInvoice>) => {
      state.items = payload;
    },
    [types.SET_MORE_INVOICES]: (state, payload: Array<ChorusInvoice>) => {
      state.items.push(...payload);
    },
    [types.SET_TOTAL_ITEMS]: (state, totalItems?: number) => {
      state.totalItems = totalItems;
    },
    [types.SET_FILTERS]: (state, filters: Filters) => {
      state.filters = filters;
    },
    [types.SET_STATUS]: (
      state,
      invoiceSubmission: ChorusInvoiceSubmissionResponse,
    ) => {
      const i = state.items.findIndex(
        (invoice) => invoice.id === invoiceSubmission.invoiceId,
      );
      if (i !== -1) {
        state.items[i].state = invoiceSubmission;
      }
    },
    [types.SET_PAGE]: (state, page: number) => {
      state.page = page;
    },
    [types.SET_SELECTED]: (state, selected: Array<ChorusInvoice>) => {
      state.selected = selected;
    },
    [types.TOGGLE_INVOICE]: (state, invoice: ChorusInvoice) => {
      const i = state.selected.findIndex((el) => el.id === invoice.id);

      if (i === -1) {
        state.selected.push(invoice);
      } else {
        state.selected.splice(i, 1);
      }
    },
    [types.SET_CONFIGURATION]: (state, configuration?: Configuration) => {
      state.configuration = configuration;
    },
  },
  actions: {
    [types.LOAD_INVOICES]: async ({ state, commit, dispatch }) => {
      commit(types.SET_TOTAL_ITEMS, undefined);
      commit(types.SET_PAGE, 1);

      const result = await getAllInvoices(state);

      commit(types.SET_INVOICES, result['hydra:member']);
      commit(types.SET_TOTAL_ITEMS, result['hydra:totalItems']);
      dispatch(types.LOAD_STATUS);
    },

    [types.LOAD_STATUS]: async ({ state, commit }) => {
      const ids = state.items.map((invoice) => invoice.id);

      const chunks = ids.reduce((all: Array<any>, one: any, i) => {
        const ch = Math.floor(i / state.filters.itemPerPage);
        all[ch] = [].concat(all[ch] || [], one);
        return all;
      }, []);

      const updateStatus = async (ids: Array<string>) => {
        const submissions = await getInvoiceSubmissionStatusAsyncByIds(ids);

        // On initialise les états à null pour indiquer qu'on a fini de charger les données
        ids.forEach((id) => {
          const i = state.items.findIndex(
            (invoice) => invoice.id === id,
          );
          if (i !== -1) {
            state.items[i].state = null;
          }
        });

        // On met à jour les états
        submissions.forEach((submission) => commit(types.SET_STATUS, submission));
      };

      await Promise.all(chunks.map((chunk) => updateStatus(chunk)));
    },

    [types.UPDATE_FILTERS]: ({ state, commit, dispatch }, filters) => {
      const mergedFilters = { ...state.filters, ...filters };
      commit(types.SET_FILTERS, mergedFilters);
      return dispatch(types.LOAD_INVOICES);
    },

    [types.LOAD_MORE]: async ({ state, commit, dispatch }) => {
      if (state.items.length === state.totalItems) return;

      commit(types.SET_PAGE, state.page + 1);

      const result = await getAllInvoices(state);

      commit(types.SET_MORE_INVOICES, result['hydra:member']);
      dispatch(types.LOAD_STATUS);
    },

    [types.LOAD_CONFIGURATION]: async ({ commit }) => {
      const result = await getAllChorusConfigAsync(1);

      if (result['hydra:member'].length) {
        commit(types.SET_CONFIGURATION, {
          id: result['hydra:member'][0].id,
          account: result['hydra:member'][0].account,
          supplierId: result['hydra:member'][0].supplierId,
        } as Configuration);
        return true;
      }
      return false;
    },
  },
  getters: {
    isInitialLoading: (state) => state.totalItems === undefined,
    account: (state) => state.configuration?.account,
  },
} as Module<ChorusState, RootState>;

<script lang="ts" setup>
/* eslint-disable */
import {
  ref, watch, computed, onMounted, Ref,
} from 'vue';
import { TransitionRoot, TransitionChild } from '@headlessui/vue';
import {
  getInvoiceSubmissionStatusAsync,
  submitInvoiceToChorusAsync,
} from '../../api/chorus/chorusInvoiceSubmissionApi';
import InvoiceSubmissionRequest from '../../models/Chorus/ChorusInvoiceSubmissionRequest';
import DrawerData from './Drawer.json';
import LoadingSpin from '../Shared/LoadingSpin.vue';
import Select from '../Shared/Select.vue';
import DrawerDetail from './DrawerDetail.vue';
import { getSlug } from '../../api/slugApi';
import { useStore } from '../../store';
import { types } from '../../store/Chorus';
import { types as configTypes } from '../../store/Configuration';
import { useI18n } from '../../i18n';

const store = useStore();
const { t } = useI18n();

store.dispatch(types.LOAD_CONFIGURATION);

const loading = ref(true);
const error = ref(null) as Ref<boolean | null>;
const errorMsg = ref('');
const selectedTaxType = ref() as Ref<string | undefined>;
const selectedPaymentMethod = ref() as Ref<string | undefined>;
const selectedInvoicingContext = ref() as Ref<string | undefined>;
const selectedExemptionReason = ref() as Ref<string | undefined>;
const selectedCommitmentForm = ref() as Ref<string | undefined>;
const commitmentNumberFreeValue = ref() as Ref<string | undefined>;
const codeStructureValideur = ref() as Ref<string | undefined>;
const codeServiceExecutant = ref() as Ref<string | undefined>;
const drawerData = ref(DrawerData);
const loadingChorus = ref(false);
const slug = getSlug();

onMounted(async () => {
  loadingChorus.value = true;
  loadingChorus.value = false;
});

const commitmentNumberForm = computed(() => {
  let disableWithoutContractNumberOption = false;
  let disableWithContractNumberOption = false;

  for (let i = 0; i < store.state.chorus.selected.length; i += 1) {
    if (!store.state.chorus.selected[i].commitmentNumber) {
      disableWithoutContractNumberOption = true;
      disableWithContractNumberOption = true;
    } else if (!store.state.chorus.selected[i].contractNumber) disableWithContractNumberOption = true;
  }

  const values = [
    { name: "Sans numéro d'engagement", value: undefined },
    {
      name: 'Numéro de commande seul',
      value: 'withoutContractNumber',
      disabled: disableWithoutContractNumberOption,
    },
    {
      name: '<Numéro de commande>_<Numéro de marché>',
      value: 'withContractNumber',
      disabled: disableWithContractNumberOption,
    },
  ];

  if (store.state.chorus.selected.length === 1) {
    values.push({
      name: 'Saisie libre',
      value: 'custom',
      disabled: false,
    });
  }

  return values;
});

const hasUnsendedInvoices = computed(() => {
  for (let i = 0; i < store.state.chorus.selected.length; i += 1) {
    if (
      store.state.chorus.selected[i].state?.status === 'REJECTED'
      || store.state.chorus.selected[i].state?.status === undefined
    ) return true;
  }
  return false;
});

const hasValidInvoice = computed(() => {
  for (let i = 0; i < store.state.chorus.selected.length; i += 1) {
    if (
      !store.state.chorus.selected[i].invalidData.length
      && store.state.chorus.selected[i].state?.status !== 'ACCEPTED'
    ) return true;
  }
  return false;
});

watch(
  () => store.state.config.rightDrawer,
  () => {
    if (!store.state.config.rightDrawer) {
      error.value = null;
      selectedTaxType.value = '';
      selectedPaymentMethod.value = '';
      selectedInvoicingContext.value = '';
      selectedExemptionReason.value = '';
      codeStructureValideur.value = '';
      codeServiceExecutant.value = '';
    }
  },
);

const send = async () => {
  error.value = null;

  if (
    !selectedInvoicingContext.value
    || !selectedPaymentMethod.value
    || !selectedTaxType.value
    || (selectedTaxType.value === 'EXONERATION'
      && !selectedExemptionReason.value)
    || ((selectedInvoicingContext.value === 'A9_FACTURE_SOUSTRAITANT'
      || selectedInvoicingContext.value === 'A12_FACTURE_COTRAITANT')
      && !codeStructureValideur.value)
  ) {
    error.value = true;
    errorMsg.value = 'Informations manquantes :';
    if (!selectedTaxType.value) errorMsg.value += '\n- Type de TVA';
    if (!selectedInvoicingContext.value) errorMsg.value += '\n- Cadre de facturation';
    if (!selectedPaymentMethod.value) errorMsg.value += '\n- Mode de réglement';
    if (
      selectedTaxType.value === 'EXONERATION'
      && !selectedExemptionReason.value
    ) errorMsg.value += "\n- Motif d'éxonération";
    if (
      (selectedInvoicingContext.value === 'A9_FACTURE_SOUSTRAITANT'
        || selectedInvoicingContext.value === 'A12_FACTURE_COTRAITANT')
      && !codeStructureValideur.value
    ) errorMsg.value += '\n- Code de la structure du valideur manquant';
    return;
  }

  loading.value = true;
  let hasError = false;
  let rejectionDetails = '';

  for (let i = 0; i < store.state.chorus.selected.length; i += 1) {
    if (
      !store.state.chorus.selected[i].invalidData.length
      && store.state.chorus.selected[i].state?.status !== 'ACCEPTED'
      && store.state.chorus.configuration?.id
    ) {
      const model: InvoiceSubmissionRequest = {
        invoiceId: store.state.chorus.selected[i].id,
        invoicingContext: selectedInvoicingContext.value,
        paymentMethod: selectedPaymentMethod.value,
        taxType: selectedTaxType.value,
        exemptionReason: selectedExemptionReason.value,
        validatorStructureCode: codeStructureValideur.value,
        configurationId: store.state.chorus.configuration?.id,
        serviceId: codeServiceExecutant.value,
      };

      if (selectedCommitmentForm.value === 'withContractNumber') {
        model.commitmentNumber = `${store.state.chorus.selected[i].commitmentNumber
        }_${
          store.state.chorus.selected[i].contractNumber}`;
      } else if (selectedCommitmentForm.value === 'withoutContractNumber') model.commitmentNumber = store.state.chorus.selected[i].commitmentNumber;
      else if (selectedCommitmentForm.value === 'custom') model.commitmentNumber = commitmentNumberFreeValue.value;

      try {
        await submitInvoiceToChorusAsync(model);
        const response = await getInvoiceSubmissionStatusAsync(
          1,
          model.invoiceId,
        );
        if (response.status === 'REJECTED') {
          hasError = true;
          if (response.message.libelle) {
            rejectionDetails
              += `Pour la facture n°${
                store.state.chorus.selected[i].reference
              }: \n -${
                response.message.libelle.split('-')[1]}`;
          } else {
            rejectionDetails
              += `Pour la facture n°${
                store.state.chorus.selected[i].reference
              }: \n -${
                response.message}`;
          }
        }
      } catch (e) {
        errorMsg.value = "Une erreur s'est produite,\n veuillez réessayer ultérieurement";
        hasError = true;
      }
    }
  }
  loading.value = false;
  if (hasError) {
    error.value = true;
    errorMsg.value = rejectionDetails;
  } else {
    error.value = false;
    setTimeout(() => {
      store.commit(configTypes.TOOGLE_DRAWER_RIGHT, false);
      store.commit(types.SET_SELECTED, []);
      loading.value = false;
    }, 1200);
  }
  store.dispatch(types.LOAD_STATUS);
};

loading.value = false;
</script>

<template>
  <div>
    <div class="space-y-4 p-4">
      <div class="flex justify-between items-center">
        <div
          v-if="store.state.chorus.selected.length === 1"
          class="flex flex-col text-blue-dark"
        >
          <p class="text-2xl">
            Facture n°{{ store.state.chorus.selected[0].reference }}
          </p>
          <p class="text-sm">
            {{
              new Date(store.state.chorus.selected[0].date)
                .toLocaleDateString()
                .replaceAll("/", "-")
            }}
          </p>
        </div>
        <div
          v-else
          class="text-blue-text flex items-baseline"
        >
          <p class="font-semibold text-xl">
            {{ store.state.chorus.selected.length }}
          </p>
          <p>&nbsp;factures sélectionnées</p>
        </div>
        <fa
          :icon="['fal', 'xmark']"
          size="2x"
          class="cursor-pointer text-blue-dark"
          @click="
            store.commit(configTypes.TOOGLE_DRAWER_RIGHT, false);
            store.commit(types.SET_SELECTED, []);
          "
        />
      </div>
      <div class="flex flex-row space-x-3 w-full items-center">
        <a
          v-if="store.state.chorus.selected.length === 1"
          :href="
            'https://' +
              slug +
              '.teo-online.net/compta/facture_edit.php?numfacture=' +
              store.state.chorus.selected[0].reference
          "
          target="_blank"
          class="border border-blue-basic text-blue-basic rounded text-sm py-1 px-3 flex items-center"
          title="Modifier la facture dans TéO"
        >
          <img
            src="./../../assets/logo_legacy.svg"
            alt="TéO"
            class="w-4 mr-2"
          >Modifier
        </a>
        <button
          :class="
            !store.state.chorus.configuration || !hasValidInvoice
              ? ' bg-gray-disabled'
              : 'border border-black'
          "
          class="rounded text-sm border border-gray-disabled disabled:opacity-50 whitespace-nowrap py-1 px-3"
          :disabled="
            loading ||
              !hasUnsendedInvoices ||
              !hasValidInvoice ||
              !store.state.chorus.configuration
          "
          @click="send"
        >
          <LoadingSpin v-if="loading" />
          <p v-else-if="!store.state.chorus.configuration">
            Aucun compte Chorus Pro
          </p>
          <fa
            v-else-if="error === false && !loading"
            :icon="['fas', 'circle-check']"
            size="lg"
            class="text-green-100"
          />
          <p v-else-if="!hasUnsendedInvoices">
            {{
              store.state.chorus.selected.length > 1
                ? "Factures envoyées"
                : "Facture envoyée"
            }}
          </p>
          <p v-else-if="!hasValidInvoice">
            {{ t("send_to_chorus_pro") }}
          </p>
          <fa
            v-else-if="error === true && !loading"
            :icon="['fas', 'circle-xmark']"
            size="lg"
            class="text-red-500"
          />
          <p v-else-if="!loading">
            {{ t("send_to_chorus_pro") }}
          </p>
        </button>
      </div>
      <p
        v-if="
          store.state.chorus.selected.length === 1 &&
            store.state.chorus.selected[0].state?.status === 'REJECTED'
        "
        class="whitespace-pre-wrap"
      >
        {{ store.state.chorus.selected[0].state?.message.libelle }}
      </p>
      <div
        v-for="invoice in store.state.chorus.selected"
        :key="invoice.reference"
        class="text-red-400"
      >
        <div v-if="invoice.invalidData.length">
          Envoi à Chorus Pro impossible pour la facture
          <a
            :href="
              'http://' +
                slug +
                '.teo-online.net/compta/facture_edit.php?numfacture=' +
                invoice.reference
            "
            target="_blank"
            class="underline text-blue-200"
            title="Modifier la facture dans TéO"
          >{{ invoice.reference }}</a>
          :
          <ul class="list-disc ml-5">
            <li
              v-for="error in invoice.invalidData"
              :key="error"
            >
              {{ t("chorus_pro_error_" + error) }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      v-if="
        hasUnsendedInvoices &&
          (store.state.chorus.selected.length > 1 ||
            (store.state.chorus.selected.length === 1 &&
              store.state.chorus.selected[0].invalidData.length === 0))
      "
      :class="
        store.state.chorus.selected.length > 1 ? 'bg-gray-200 h-full' : ''
      "
      class="space-y-4 px-6"
    >
      <div
        v-if="store.state.chorus.selected.length === 1"
        class="pb-2 border-b border-black"
      >
        <h4 :class="error ? 'border-red-border' : 'border-black'">
          {{ t("chorus_pro_data") }}
        </h4>
      </div>
      <p v-if="error">
        {{ errorMsg }}
      </p>
      <div
        v-else
        class="text-red-text grid grid-flow-row justify-items-center space-y-2 px-2"
      >
        <fa
          :icon="['fas', 'triangle-exclamation']"
          size="2x"
          class="text-red-text"
        />
        <div>
          <div class="text-sm">
            Complétez les données ci-dessous avant l'envoi
          </div>
        </div>
      </div>
      <div
        class="space-y-6"
        :class="error ? 'text-red-border' : ''"
      >
        <div class="space-y-1">
          <label
            for="tax"
            class="flex"
          >Type de TVA <p class="text-red-300">*</p></label>
          <p
            id="tax"
            name="tax"
          >
            <Select
              v-model="selectedTaxType"
              :options="drawerData['taxType']"
              class="border rounded w-full"
              :class="error ? 'border-red-border' : 'border-black'"
            />
          </p>
        </div>

        <TransitionRoot :show="selectedTaxType === 'EXONERATION'">
          <TransitionChild
            enter="transition-opacity ease-linear duration-150"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity ease-linear duration-150"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="space-y-1">
              <label
                for="exemptionReason"
                class="flex"
              >Motif d'exonération <p class="text-red-300">*</p></label>
              <p
                id="exemptionReason"
                name="exemptionReason"
              >
                <Select
                  v-model="selectedExemptionReason"
                  :options="drawerData['exemptionReason']"
                  class="border rounded w-full"
                  :class="error ? 'border-red-border' : 'border-black'"
                />
              </p>
            </div>
          </TransitionChild>
        </TransitionRoot>

        <div class="space-y-1">
          <label
            for="invoiceContext"
            class="flex"
          >Cadre de facturation <p class="text-red-300">*</p></label>
          <p
            id="invoiceContext"
            name="invoiceContext"
          >
            <Select
              v-model="selectedInvoicingContext"
              :options="drawerData['invoicingContext']"
              class="border rounded w-full"
              :class="error ? 'border-red-border' : 'border-black'"
            />
          </p>
        </div>
        <TransitionRoot
          :show="
            selectedInvoicingContext === 'A9_FACTURE_SOUSTRAITANT' ||
              selectedInvoicingContext === 'A12_FACTURE_COTRAITANT'
          "
        >
          <TransitionChild
            enter="transition-opacity ease-linear duration-150"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity ease-linear duration-150"
            leave-from="opacity-100"
            leave-to="opacity-0"
            class="space-y-1"
          >
            <p class="flex">
              Code de la structure valideur <span class="text-red-300">*</span>
            </p>
            <input
              v-model="codeStructureValideur"
              class="w-full h-8 border pl-2 rounded"
              placeholder="Entrez le code de la structure valideur"
              :class="error ? 'border-red-border' : 'border-black'"
            >
          </TransitionChild>
        </TransitionRoot>
        <div class="space-y-1">
          <label
            for="paymentMethod"
            class="flex"
          >Mode de règlement<p class="text-red-300">*</p></label>
          <p
            id="paymentMethod"
            name="paymentMethod"
          >
            <Select
              v-model="selectedPaymentMethod"
              :options="drawerData['paymentMethod']"
              class="border rounded w-full"
              :class="error ? 'border-red-border' : 'border-black'"
            />
          </p>
        </div>
        <div class="space-y-1">
          <label for="tax">Numéro d'engagement</label>
          <p
            id="tax"
            name="tax"
          >
            <Select
              v-model="selectedCommitmentForm"
              :options="commitmentNumberForm"
              class="border rounded w-full"
              :class="error ? 'border-red-border' : 'border-black'"
            />
          </p>
        </div>
        <TransitionRoot :show="selectedCommitmentForm === 'custom'">
          <TransitionChild
            enter="transition-opacity ease-linear duration-150"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity ease-linear duration-150"
            leave-from="opacity-100"
            leave-to="opacity-0"
            class="space-y-1"
          >
            <p>Numéro d'engagement</p>
            <input
              v-model="commitmentNumberFreeValue"
              class="w-full h-8 border pl-2 rounded"
              placeholder="Entrez le numéro d'engagement"
              :class="error ? 'border-red-border' : 'border-black'"
            >
          </TransitionChild>
        </TransitionRoot>
        <div class="space-y-1">
          <label for="service">Code service exécutant</label>
          <p
            id="service"
            name="service"
          >
            <input
              v-model="codeServiceExecutant"
              class="w-full h-8 border pl-2 rounded"
              placeholder="Entrez le code du service exécutant"
              :class="error ? 'border-red-border' : 'border-black'"
            >
          </p>
        </div>
      </div>
    </div>
    <DrawerDetail
      v-if="store.state.chorus.selected.length === 1"
      class="px-8 py-5"
    />
  </div>
</template>
